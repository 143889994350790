.blog_reivew_button__bar {
  vertical-align: middle;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 100%;
  text-align: center;
  bottom: -40;
}

.blog_reivew_button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.blog_reivew_button__bar li button {
  border: none;
  background: #b2b2b2;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  opacity: 0.4;
  padding: 0;
  border-radius: 10px;
}

.blog_reivew_button__bar li.slick-active button {
  opacity: 1;
}

.blog_reivew_button__bar li button:hover,
.blog_reivew_button__bar li button:focus {
  outline: 0;
}
