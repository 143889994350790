.button__bar {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: 0;
  margin: auto;
  padding: 0;
  width: 100%;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #ffffff;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 3px;
  width: 50px;
  opacity: 0.3;
}

.button__bar li.slick-active button {
  opacity: 1;
}

.button__bar li button:hover,
.button__bar li button:focus {
  outline: 0;
}
